<template>
  <el-container>
    <el-header height="220">
      <el-row style="margin-top: 10px">
        <el-button type="plain" icon="el-icon-plus" @click="addSubjectVisible = true">添加</el-button>
      </el-row>
    </el-header>
    <el-main>
      <el-table
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          fixed="left"
          label="No"
          type="index"
        />
        <el-table-column
          prop="subjectName"
          label="科目"
        />
        <el-table-column
          prop="question7Count"
          label="单选题"
        />
        <el-table-column
          prop="question7Count"
          label="多选题"
        />
        <el-table-column
          prop="question7Count"
          label="不定项选择题"
        />
        <el-table-column
          prop="question7Count"
          label="填空题"
        />
        <el-table-column
          prop="question7Count"
          label="判断题"
        />
        <el-table-column
          prop="question7Count"
          label="问答题"
        />
        <el-table-column
          prop="question7Count"
          label="理解题"
        />
        <el-table-column
          prop="paperCount"
          label="试卷"
        />
      </el-table>
      <el-pagination
        style="margin-top: 25px"
        :small="screenWidth <= 768"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalSize"
        @current-change="handleCurrentChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      />
    </el-main>
  </el-container>
</template>

<script>
import { getSubject } from '@/api/exam'

export default {
  name: 'ExamSubject',
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 20,
      totalSize: 0,
      dataList: [],
      addSubjectVisible: false
    }
  },
  created() {
    document.title = '科目管理'
    this.getData()
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.getData()
      // 回到顶部
      scrollTo(0, 0)
    },
    getData() {
      this.dataList = []
      getSubject(this.currentPage).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data.list
          this.totalSize = resp.data.totalSize
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style>
</style>
